<template>
	<div class="container infoPage ">
		<InformationMenu></InformationMenu>
		<div class="content">
			<h1 class="title heading mrb-10">Возврат и обмен</h1>
			<div class="text-group">
				<div class="text-block">
					<h3 class="header mrb-4">Обмен и возврат без проблем</h3>
					<p class="text_base1_bold blue_80 mrt-0 mrb-0">
						Купили и передумали? Вернем деньги или обменяем товар в течении 14-ти дней
					</p>
					<h4 class="heading mrb-4 black_100 mrt-10">
						Что необходимо для возврата?
					</h4>
					<ul class="list-verified text_base1">
						<li>
							<span class="ok">
								<svg width="28" height="28" viewBox="0 0 28 28" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path d="M5 15.4286L11.6271 22L22 6" stroke="#2B47DA" stroke-width="2"
										stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</span>
							Прошло не более 14 дней
						</li>
						<li>
							<span class="ok">
								<svg width="28" height="28" viewBox="0 0 28 28" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path d="M5 15.4286L11.6271 22L22 6" stroke="#2B47DA" stroke-width="2"
										stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</span>
							Товарный вид сохранен
						</li>
						<li>
							<span class="ok">
								<svg width="28" height="28" viewBox="0 0 28 28" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path d="M5 15.4286L11.6271 22L22 6" stroke="#2B47DA" stroke-width="2"
										stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</span>
							Сохранен кассовый чек
						</li>
					</ul>
				</div>
			</div>

			<div class="text-group">
				<div class="text-block">
					<h3 class="header mrb-4 mrt-20">Возврат или обмен товара надлежащего качества</h3>
					<h4 class="heading mrb-4 black_100 mrt-8">
						Что необходимо для возврата?
					</h4>
					<ol class="numbered-list text_base1">
						<li>Прошло не более 14 дней;</li>
						<li>Не был в использовании (сохранен товарный вид, заводские пломбы, ярлыки, заводская
							упаковка);</li>
						<li>Наличие подтверждения покупки (чек, расх.накладная);</li>
						<li>Полная комплектация;</li>
						<li>Акт диагностики от Авторизованного СЦ (подтверждающий факт, что товар исправен и не был в
							использовании).</li>
					</ol>
					<h4 class="heading mrb-4 black_100 mrt-10">
						Как обменять или вернуть товар?
					</h4>
					<div class="round-numbered text_base1">
						<p class="round-num mrt-0 mrb-0">
							<span class="num"><svg width="28" height="28" viewBox="0 0 28 28" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<circle cx="14.5" cy="13.5" r="12.5" stroke="#2B47DA" stroke-width="2" />
									<path
										d="M16.4485 7.68188V19.3182H14.3406V9.73302H14.2724L11.5508 11.4717V9.53984L14.4428 7.68188H16.4485Z"
										fill="#2B47DA" />
								</svg>
							</span>Привезите товар и кассовый чек
						</p>
						<svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M15 4C15 3.44772 14.5523 3 14 3C13.4477 3 13 3.44772 13 4L15 4ZM13.2929 20.7071C13.6834 21.0976 14.3166 21.0976 14.7071 20.7071L21.0711 14.3431C21.4616 13.9526 21.4616 13.3195 21.0711 12.9289C20.6805 12.5384 20.0474 12.5384 19.6569 12.9289L14 18.5858L8.34315 12.9289C7.95262 12.5384 7.31946 12.5384 6.92893 12.9289C6.53841 13.3195 6.53841 13.9526 6.92893 14.3431L13.2929 20.7071ZM13 4L13 20L15 20L15 4L13 4Z"
								fill="#C9C9C9" />
						</svg>
						<p class="round-num mrt-0 mrb-0">
							<span class="num"><svg width="28" height="28" viewBox="0 0 28 28" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<circle cx="14.5" cy="13.5" r="12.5" stroke="#2B47DA" stroke-width="2" />
									<path
										d="M10.5447 19.8977V18.375L14.5845 14.4148C14.9709 14.0246 15.2929 13.6781 15.5504 13.375C15.808 13.072 16.0012 12.7784 16.13 12.4943C16.2588 12.2102 16.3232 11.9072 16.3232 11.5852C16.3232 11.2178 16.2398 10.9034 16.0732 10.6421C15.9065 10.3769 15.6773 10.1724 15.3857 10.0284C15.094 9.88449 14.7625 9.81252 14.3913 9.81252C14.0088 9.81252 13.6735 9.89207 13.3857 10.0512C13.0978 10.2065 12.8743 10.4281 12.7152 10.7159C12.5599 11.0038 12.4822 11.3466 12.4822 11.7443H10.4766C10.4766 11.0057 10.6451 10.3637 10.9822 9.8182C11.3194 9.27275 11.7834 8.8504 12.3743 8.55116C12.969 8.25192 13.6508 8.10229 14.4197 8.10229C15.2 8.10229 15.8857 8.24813 16.4766 8.53979C17.0675 8.83146 17.5258 9.23108 17.8516 9.73866C18.1811 10.2462 18.3459 10.8258 18.3459 11.4773C18.3459 11.9129 18.2625 12.3409 18.0959 12.7614C17.9292 13.1818 17.6357 13.6477 17.2152 14.1591C16.7985 14.6705 16.2133 15.2898 15.4595 16.0171L13.4538 18.0568V18.1364H18.522V19.8977H10.5447Z"
										fill="#2B47DA" />
								</svg>
							</span>Обратитесь к продавцу
						</p>
						<svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M15 4C15 3.44772 14.5523 3 14 3C13.4477 3 13 3.44772 13 4L15 4ZM13.2929 20.7071C13.6834 21.0976 14.3166 21.0976 14.7071 20.7071L21.0711 14.3431C21.4616 13.9526 21.4616 13.3195 21.0711 12.9289C20.6805 12.5384 20.0474 12.5384 19.6569 12.9289L14 18.5858L8.34315 12.9289C7.95262 12.5384 7.31946 12.5384 6.92893 12.9289C6.53841 13.3195 6.53841 13.9526 6.92893 14.3431L13.2929 20.7071ZM13 4L13 20L15 20L15 4L13 4Z"
								fill="#C9C9C9" />
						</svg>
						<p class="round-num mrt-0 mrb-0">
							<span class="num"><svg width="28" height="28" viewBox="0 0 28 28" fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<circle cx="14.5" cy="13.5" r="12.5" stroke="#2B47DA" stroke-width="2" />
									<path
										d="M14.4858 19.9545C13.6676 19.9545 12.9403 19.8144 12.304 19.5341C11.6714 19.2538 11.1714 18.8636 10.804 18.3636C10.4366 17.8636 10.2415 17.286 10.2188 16.6307H12.3551C12.3741 16.9451 12.4782 17.2197 12.6676 17.4545C12.857 17.6856 13.1089 17.8655 13.4233 17.9943C13.7377 18.1231 14.09 18.1875 14.4801 18.1875C14.8968 18.1875 15.2661 18.1155 15.5881 17.9716C15.91 17.8239 16.1619 17.6193 16.3438 17.358C16.5256 17.0966 16.6146 16.7955 16.6108 16.4545C16.6146 16.1023 16.5237 15.7917 16.3381 15.5227C16.1525 15.2538 15.8835 15.0436 15.5312 14.892C15.1828 14.7405 14.7623 14.6648 14.2699 14.6648H13.2415V13.0398H14.2699C14.6752 13.0398 15.0294 12.9697 15.3324 12.8295C15.6392 12.6894 15.8797 12.4924 16.054 12.2386C16.2282 11.9811 16.3134 11.6837 16.3097 11.3466C16.3134 11.017 16.2396 10.7311 16.0881 10.4886C15.9403 10.2424 15.7301 10.0511 15.4574 9.91477C15.1884 9.77841 14.8722 9.71023 14.5085 9.71023C14.1525 9.71023 13.8229 9.77462 13.5199 9.90341C13.2169 10.0322 12.9725 10.2159 12.7869 10.4545C12.6013 10.6894 12.5028 10.9697 12.4915 11.2955H10.4631C10.4782 10.6439 10.6657 10.072 11.0256 9.57955C11.3892 9.08333 11.8741 8.69697 12.4801 8.42045C13.0862 8.14015 13.7661 8 14.5199 8C15.2964 8 15.9706 8.14583 16.5426 8.4375C17.1184 8.72538 17.5634 9.11364 17.8778 9.60227C18.1922 10.0909 18.3494 10.6307 18.3494 11.2216C18.3532 11.8769 18.16 12.4261 17.7699 12.8693C17.3835 13.3125 16.8759 13.6023 16.2472 13.7386V13.8295C17.0653 13.9432 17.6922 14.2462 18.1278 14.7386C18.5672 15.2273 18.785 15.8352 18.7812 16.5625C18.7812 17.214 18.5956 17.7973 18.2244 18.3125C17.857 18.8239 17.3494 19.2254 16.7017 19.517C16.0578 19.8087 15.3191 19.9545 14.4858 19.9545Z"
										fill="#2B47DA" />
								</svg>
							</span>Получите товар на замену или вернем деньги
						</p>
					</div>
				</div>
			</div>

			<div class="text-group">
				<div class="text-block">
					<h3 class="header mrb-4 mrt-20">Возврат или обмен товара ненадлежащего качества</h3>
					<p class="text_base1 black_100 ">
						Возврат или обмен товара ненадлежащего качества (подразумевается товар, который неисправен и
						не может обеспечить исполнение своих функциональных качеств) осуществляется при
						предоставлении клиентом следующих документов (статья 30 п.2 Закона РК «О защите прав
						потребителей»)
					</p>
					<h4 class="heading mrb-4 black_100 mrt-10">
						Что необходимо для обмена и возврата?
					</h4>
					<ol class="numbered-list text_base1">
						<li>Кассовый чек или документ, подтверждающий покупку в магазине;</li>
						<li>Заявление покупателя на возврат/обмен товара на имя директора магазина с указанием
							причины возврата/обмена;</li>
						<li>Гарантийный талон (если предусмотрен); </li>
						<li>Документ, подтверждающий наличие в товаре заводского брака (Акт от Авторизованного
							сервисного центра с подтверждением заявленного дефекта и отсутствием нарушений правил
							эксплуатации.)</li>
					</ol>
				</div>
			</div>

			<div class="text-group">
				<div class="text-block">
					<h3 class="header mrb-4 mrt-20">Товары, не подлежащие обмену или возврату</h3>
					<ul class="list-dotted">
						<li>Товар был в употреблении;</li>
						<li>Не сохранены его товарный вид;</li>
						<li>Не сохранены потребительские свойства, пломбы, ярлыки;</li>
						<li>Отсутствует документ, подтверждающий факт приобретения товара, в течение четырнадцати
							календарных дней.</li>
					</ul>
				</div>
			</div>

		</div>
	</div>
	<div class="container mgt-35">
		<careServiceWidget />
	</div>
</template>

<script>
import careServiceWidget from '@/components/careServiceWidget.vue';
import InformationMenu from "@/components/InformationMenu.vue";

export default {
	metaInfo() {
		return {
			title: this.$route.meta.title,
			meta: [
				{
					name: 'og:url',
					content: this.$route.meta.ogUrl,
				},
				{
					name: 'og:image',
					content: this.$route.meta.ogImage,
				},
				{
					name: 'og:description',
					content: this.$route.meta.description,
				},
				// Другие метаданные
			],
		};
	},
	components: {
		careServiceWidget,
		InformationMenu,
	},
	name: "returningPage",
	props: {},
};
</script>

<style>
.infoPage {
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	margin-block: 117px 140px !important;
}

.content {

	min-width: calc(100% - 365px);
}

.infoPage .numbered-list {
	list-style: none;
	counter-reset: custom-counter;
	padding-left: 36px;
}

.infoPage .numbered-list li {
	position: relative;
}

.infoPage .numbered-list li:before {
	counter-increment: custom-counter;
	content: counter(custom-counter) ".";
	color: #2B47DA;
	margin-left: -20px;
	position: absolute;
	font-weight: 600;
}

.infoPage .numbered-list li+li,
.infoPage .list-dotted li+li {
	margin-top: 20px;
}

.infoPage .list-dotted {
	list-style: none;
	padding-left: 36px;
}

.infoPage .list-dotted li {
	position: relative;
}

.infoPage .list-dotted li:before {
	content: "";
	margin-left: -15px;
	position: absolute;
	font-weight: 600;
	height: 5px;
	width: 5px;
	display: block;
	background: #2B47DA;
	border-radius: 50%;
	top: 10px;
}

.infoPage .list-dotted li a {
	margin: 8px 0 0;
	display: block;
}

.infoPage h3.header {
	font-weight: 600;
}

.infoPage .list-dotted {
	padding-left: 25px;
}

@media (max-width: 900px) {
	.infoPage {
		margin-block: 100px 0px !important;
	}

	h1.heading {
		margin-bottom: 40px !important;
	}

	#app>div>.container.infoPage {
		padding-inline: 10px !important;
	}

	.content {
		flex-basis: 100%;
	}
}
</style>
<style scoped lang="scss">
.list-verified li+li {
	margin-top: 16px;
}

.list-verified {
	padding-left: 0;
}

.list-verified li {
	display: flex;
	align-items: center;
	gap: 8px;
}

.round-numbered {
	display: flex;
	flex-flow: column;
	gap: 8px;
}

.round-numbered p {
	display: flex;
	flex-flow: row nowrap;
	gap: 8px;
	align-items: center;
}
</style>
<style lang="scss">

@media (max-width:900px) {
	#app>div>.container.mgt-35 {
		padding-left: 20px !important;
		max-width: unset;
		width: 100%;
		padding-right: 20px !important;
	}
}
</style>