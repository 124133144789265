<template>
	<div class="container infoPage ">
		<InformationMenu></InformationMenu>
		<div class="content">
			<h1 class="title heading mrb-10">Публичная оферта</h1>
			<p class="text_base2">
				Внимательно ознакомьтесь с текстом публичной оферты, и если Вы не согласны с каким-либо пунктом оферты, Вы
				вправе отказаться от покупки Товаров, предоставляемых Продавцом, и не совершать действий, указанных в п.
				2.1. настоящей Оферты.
			</p>
			<p class="text_base2">
				Розничные магазины Samsung расположеные по адресу: Казахстан, г. Алматы, Самал-2, 111, ТРЦ
				Dostyk Plaza в лице ТОО «Компания Эврика», БИН 120140015907, именуемый в дальнейшем
				«Продавец», публикует Публичную оферту о продаже Товаров в розничных магазинах о нижеследующем:
			</p>
			<h4 class="heading mrt-20 mrb-5">Определение терминов</h4>
			<ul class="numbered-list double list_one text_base2">
				<li>
					Публичная оферта (далее – «Оферта») - публичное предложение Продавца, адресованное неопределенному кругу
					лиц, заключить с Продавцом договор купли-продажи товара (далее - «Договор») на условиях, содержащихся в
					настоящей Оферте, включая все Приложения.
				</li>
				<li>
					ЗПП – Закон Республики Казахстан «О защите прав потребителей» № 274-IV от 4 мая 2010 года.
				</li>
				<li>
					Розничный магазин – это торговое помещение, где реализуется товары под брендом Samsung.
				</li>
			</ul>

			<h4 class="heading mrt-20 mrb-5">Общие положения</h4>
			<ul class="numbered-list double list_two text_base2">
				<li>
					Оплата (полная или частичная) товара в розничных магазинах означает, что Покупатель согласен со всеми
					условиями настоящей Оферты и ее безусловное акцептование.
				</li>
				<li>
					Срок действия Оферты не ограничен.
				</li>
				<li>
					Продавец предоставляет Потребителю полную и достоверную информацию о Товаре, включая информацию об
					основных потребительских свойствах Товара.
				</li>
				<li>
					Характеристики и внешний вид товара могут отличаться опубликованные на сайте.
				</li>
				<li>
					Продавец оставляет за собой право изменять условия договора в одностороннем порядке до момента его
					заключения.
				</li>
				<li>
					Покупка Потребителем товара означает дачу согласия Потребителя на сбор, хранение, обработку и передачу
					персональных данных Продавцом. Любые условия оферты, включая условия акции (услуг или сервисов),
					опубликованные в социальных сетях, вебсайте или в розничных магазинах могут быть изменены/прекращены в
					одностороннем порядке Продавцом.
				</li>
				<li>
					Приняв настоящую Оферту потребители приняли обязательство самостоятельно проверять изменения, вносимые в
					Оферту, в условия акций, услуг и работы, путем посещения интернет-ресурсов: <a href="https://smbrand.kz"
						target="_blank">https://smbrand.kz/</a>
				</li>
			</ul>

			<h4 class="heading mrt-20 mrb-5">Цена товара</h4>
			<ul class="numbered-list double list_three text_base2">
				<li>
					Стоимость Товара указывается на ценнике путем отражения предварительной стоимости. Окончательная и
					верная стоимость Товара указывается перед утверждением Заказа и сообщается Потребителю.
				</li>
				<li>
					Перед отпуском Товара Продавец проверяет стоимость товара на правильность, согласно внутренним сведениям
					Продавца и сообщает правильную и полную стоимость Товара Потребителю путем устного или письменного
					уведомления.
				</li>
				<li>
					В случае выявления Продавцом неверной стоимости Товара, который был заказан и / или оплачен
					Потребителем, Продавец вправе:
					<ul class="numbered-list triple list_three_sub text_base2">
						<li>в одностороннем порядке увеличить стоимость товара путем выставления счета Потребителю с
							правильной стоимостью товара, согласно пп.7 п. 2 ст. 8-1 ЗПП (внесением изменения в Заказ).
						</li>
						<li>При выставлении Продавцом счета, указанного в пункте 3.3.1. Оферты, Потребителю, Потребитель
							вправе оплатить корректную стоимость товара, отказаться оплатить стоимость товара (приобрести
							товар), а также расторгнуть Договор с Продавцом (путем отказа оплатить счет), если договор был
							заключен между сторонами. Если потребитель не оплатил сумму, указанную в счете в течение одного
							дня, Договор считается расторгнутым Потребителем, если он был заключен, а Заказ отменяется без
							передачи товара Потребителю. </li>
						<li>В случае отказа Потребителем оплатить счет с правильной стоимостью Товара, Договор считается
							расторгнутым, если он был заключен, а Заказ отменяется, и в этом случае Продавец обязан
							возвратить деньги Потребителю (если они были получены), а Потребитель обязан вернуть полученный
							Товар Продавцу в товарном виде с отсутствием следов эксплуатации и этикетки (если Потребитель
							получил Товар). </li>
						<li>Продавец вправе расторгнуть Договор в одностороннем порядке, отказать в выдаче товара, если
							Потребитель не оплатил полную стоимость Товара, в этом случае Продавец обязан возвратить
							Потребителю полученные от него денежные средства (если Товар был частично оплачен), а
							Потребитель обязан вернуть приобретенный товар Продавцу в товарном виде с отсутствием следов
							эксплуатации и этикетки (если Товар был получен Потребителем). </li>
					</ul>
				</li>
			</ul>

			<h4 class="heading mrt-20 mrb-5">Оформление заказа</h4>
			<ul class="numbered-list double list_four text_base2">
				<li>
					Заказ Товара осуществляется Потребителем в розничных - магазинах Samsung
				</li>
				<li>
					Если Продавцу необходима дополнительная информация, он вправе запросить ее у Потребителя. В случае не
					предоставления необходимой информации Потребителем, Продавец не несет ответственности за выбранный
					Потребителем Товар.
				</li>
				<li>
					Продавец не несет ответственности, если ожидания потребителя о потребительских свойствах товара не
					оправдались.
				</li>
				<li>
					Договор купли-продажи между Продавцом и Потребителем считается заключенным с момента выдачи Продавцом
					Потребителю кассового или товарного чека либо иного документа, подтверждающего оплату Товара.
				</li>
				<li>
					Информация о Товаре доводится до сведения Потребителя в технической документации, прилагаемой к Товару,
					на этикетках, путем нанесения маркировки или иным способом, принятым для отдельных видов товаров.
				</li>
			</ul>


			<h4 class="heading mrt-20 mrb-5">Возврат и обмен товара</h4>
			<ul class="numbered-list double list_five text_base2">
				<li>
					Требование потребителя об обмене либо о возврате товара подлежит рассмотрению в соответствии с Законом
					Республики Казахстан «О защите прав потребителей»
				</li>
				<li>
					Потребитель компенсирует продавцу необходимые транспортные расходы, понесенные в связи с организацией
					обмена или возврата товара надлежащего качества.
				</li>
				<li>
					При возврате товара, за который(ые) ранее (на момент приобретения) были начислены бонусы, производится
					обратное списание/сторнирование бонусов в размере начисленных. В случае если бонусы потрачены
					потребителем, потребитель обязан вернуть размер потраченного бонуса в счет продавца; продавец имеет
					право в одностороннем порядке удержать размер начисленного бонуса из суммы возвращаемых потребителю
					денег за возвращённый товар, если потребитель самостоятельно не исполнил обязательство по возврату
					использованного бонуса в силу п.1 ст.953 Гражданского кодекса Республики Казахстан.
				</li>
				<li>
					В случае удовлетворения продавцом требования потребителя о возврате стоимости товара потребителю, либо,
					удовлетворения требования об обмене товара на другой товар, потребитель обязан самостоятельно явиться в
					тот розничный магазин, где был приобретен товар для целей возврата и обмена.
				</li>
				<li>
					При возврате товара, оформленного потребителем через банки второго уровня и микрофинансовые организации
					в рассрочку или кредит (далее - Кредитор) по финансовым инструментам «рассрочка/кредит»,
					сторнирование/возврат кредитных средств Продавцом потребителю производятся на счет(а) Кредитора в тех
					случаях, когда такое условие установлено банками второго уровня и микрофинансовыми организациями.
				</li>
			</ul>
			<p class="text_base2 mrt-5 mrb-0">
				Данной офертой потребитель считается надлежащим образом уведомленным.
			</p>
			<h4 class="heading mrt-20 mrb-5">Реквизиты продавца</h4>
			<b>Продавец:</b>
			<ul class="list-info">
				<li>ТОО «Компания Эврика»</li>
				<li>Юридический адрес: 160019, Республика Казахстан</li>
				<li>г. Шымкент, ул. Байтурсынова, 18а (БЦ Scrum)</li>
				<li>БИН 120140015907</li>
				<li>Р/сч KZ546010291000155233</li>
				<li>АО «Народный Банк Казахстана»</li>
				<li>БИКHSBKKZKX</li>
				<li>Тел: 8 771 933 33 11</li>
				<li>Адрес эл. почты: <a href="email:office@еvrika.com">office@еvrika.com</a></li>
			</ul>
		</div>
	</div>
	<div class="container mgt-35">
		<careServiceWidget />
	</div>
</template>
<script>
import InformationMenu from '@/components/InformationMenu.vue'
import careServiceWidget from '@/components/careServiceWidget.vue';

export default {
	components: {
		InformationMenu,
		careServiceWidget
	},
	name: 'ofertaPage',
	props: {
	},
}
</script>
<style>
.infoPage {
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	margin-block: 117px 140px !important;
}

.content {
	flex-basis: calc(100% - 365px);
}

@media (max-width: 900px) {
	.infoPage {
		margin-block: 101px 100px !important;
	}

	h1.heading {
		margin-bottom: 40px !important;
	}

	#app>div>.container.infoPage {
		padding-inline: 10px !important;
	}

	.content {
		flex-basis: 100%;
	}
}
</style>
<style scoped lang="scss">
.infoPage .numbered-list {
	list-style: none;
	counter-reset: custom-counter;
	padding-left: 40px;

	&.double {
		&.list_one li:before {
			content: "1." counter(custom-counter);
			margin-left: -36px;
		}

		&.list_two li:before {
			content: "2." counter(custom-counter);
			margin-left: -36px;
		}

		&.list_three li:before {
			content: "3." counter(custom-counter);
			margin-left: -36px;
		}

		&.list_three li:before {
			content: "3." counter(custom-counter);
			margin-left: -36px;
		}

		&.list_four li:before {
			content: "4." counter(custom-counter);
			margin-left: -36px;
		}

		&.list_five li:before {
			content: "5." counter(custom-counter);
			margin-left: -36px;
		}


	}

	& li>ul {
		margin-top: 20px;
	}
}

.infoPage .numbered-list.triple {
	padding-left: 54px;

	&.list_three_sub li:before {
		content: "3.3." counter(custom-counter);
		margin-left: -48px;
	}
}

.infoPage .numbered-list li {
	line-height: 1.5;
	font-size: 18px;
}

.list-info {
	padding: 0;
	margin: 12px 0 0;

	& li {
		margin: 0 0 8px;
		font-size: 16px;
	}
}
.text_base2.mrt-5.mrb-0 ~ b{
	font-size: 18px;
}

@media (max-width: 900px) {
	.text_base2 {
		line-height: 1.5;
		font-size: 16px;
	}
}

a {
	color: #1D39C9;
}
</style>

<style lang="scss">
@media (max-width:900px) {
	#app>div>.container.mgt-35 {
		padding-left: 20px !important;
		max-width: unset;
		width: 100%;
		padding-right: 20px !important;
	}
}
</style>